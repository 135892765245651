<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        IoT and AI/ML in Pharmaceutical Process
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104027/african-woman-biochemist-researcher-checking-manifestations-vaccine-working-modern-equipped-laboratory-multiethnic-doctors-examining-virus-evolution-using-high-tech-researching-diagnosis_sg4c9g.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            IoT and AI/ML in Pharmaceutical Process
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Nov 4, 2021</p>
            </span>
          </div>
          <p>
            The Pharmaceutical industry is one with many peculiarities and
            stringent regulations in place to ensure the integrity of products
            and processes, and the role IoT and AI/ML play when it comes to
            ensuring efficiency and integrity can’t be undermined.
          </p>
          <p>
            The world of pharmacy is considered the backbone of healthcare; this
            means efforts made to constantly refine their process will have
            profound impacts on humans at large. The first three industrial
            revolutions saw some advancements in the pharmaceutical space and
            other industries but, with IoT and AI/ML leading the 4th and 5th
            industrial revolutions, the capabilities of industries are almost
            limitless.
          </p>
          <p>
            In less than a century, communication has gone from mailing letters
            via post offices to instant messaging on social media, most bank
            transactions no longer require stepping foot in the bank, and online
            retail is now at the heart of commerce, it is quite evident the
            level of advancement technology and the internet has afforded
            several industries over the years and, the pharmaceutical space is
            no exception.
          </p>
          <p>
            From being the brain of Moderna’s fully integrated “Facility of the
            future” to ensuring the integrity of pharmaceutical products at the
            last mile, IoT and AI/ML technology have been at the centre of
            innovation within the Pharmaceutical space. Below are some areas
            Industry leaders and key players in the pharma world have been
            engaging IoT and AI/ML in optimising processes;
          </p>

          <h3 class="font-weight-bold mb-4">Manufacturing</h3>
          <p class="font-weight-bold">
            Compliance and Quality Assurance
          </p>
          <p>
            Contamination is another operational threat whose effect has been
            mitigated with IoT and AI/ML. In 2009 Genzyme (now part of Sanofi
            Genzyme) had to temporarily close its Massachusetts plant after a
            virus was found to have contaminated a bioreactor, having to halt
            production of the Fabrazyme and Cerezyme drugs that were at the time
            used by 8,000 patients globally, at the time, it was estimated that
            the incident could cost Genzyme up to $300m in revenue lost.
          </p>

          <p>
            Contamination within the pharmaceutical manufacturer process is
            largely due to human error and in a space where Compliance and
            Quality Assurance are non-negotiable, big pharma like Sanofi and
            Moderna use automation, real-time monitoring and robotics (all of
            which are interesting by products of IoT and AI/ML) to rid their
            manufacturing processes of the costly effect of human error, and
            achieve a high level of process transparency.
          </p>

          <p class="font-weight-bold">
            Predictive Maintenance
          </p>
          <p>
            The pharmaceutical industry loses billions to inefficiencies in its
            operations and processes every year, one of the key contributors to
            these losses is equipment failure, with IoT and AI/ML, Pharma
            companies now predict using previous data, the possibility of an
            equipment failure up to 60days out, this gives enough room for
            predictive maintenance, help avoid production interruptions as well
            as losses that could come as a result of equipment failure.
          </p>

          <p class="font-weight-bold">
            Efficiency
          </p>
          <p>
            This is one major area where IoT and AI/ML has afforded the pharma
            industry some noteworthy disruptions. During AWS re:Invent 2019, the
            director of GSP systems at Moderna, Roland Smith mentioned how they
            were able to reduce batch review time from 3days to 3hrs, and shift
            labour by 40percent using IoT and AI/ML. The effect of these
            disruptions in their process is definitely one with a profound
            impact on operation efficiency and their bottom line.
          </p>

          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104093/interior-empty-science-laboratory-with-modern-equipment-prepared-pharmaceutical-innovation-using-high-tech-microbiology-tools-scientific-research-vaccine-development-against-covid19-virus_metgpk.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>

          <h3 class="font-weight-bold">
            Clinical Trials and Personalised Medicine
          </h3>
          <p class="font-italics">
            "Pharma spends 2.7 billion dollars for each drug that makes it to
            market and what that accounts for is that 90percent of drugs fail in
            clinical trials" - Elizabeth Krutoholow, Senior Healthcare Analyst,
            Bloomberg intelligence.
          </p>
          <p>
            It is almost impossible to not notice the inefficiencies plaguing
            the pharma space in the areas of clinical trials and personalized
            medicine, especially when the numbers are glaringly astounding.
            Clinical trial is a key tool the pharmaceutical industry relies on
            to confirm the potency of its products, with the need for
            personalised medicine hitting an all time high, the crude process of
            clinical trials needs a major disruption for the pharmaceutical
            industry to be able to meet up with demands. One of the reasons
            responsible for most drugs failing in clinical trials is the fact
            that patients are identified too late in the process.
          </p>

          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636104071/cut-out-medicament-drug-doctor-medical_slklhq.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>
          <p>
            Elizabeth Krutoholow at Bloomberg's "Sooner Than You Think" also
            mentioned how effective AI will be in mining patient records to find
            the best patient for the clinical test of any new drug as well as
            predict with a high level of accuracy, people who are developing a
            life threatening disease or at risk of it. This will essentially
            reduce the time it takes to find patients for clinical trials,
            reduce the possibility of drugs failing in the pipeline and beam a
            ray of hope for patients with life threatening illness either
            practically or potentially.
          </p>
          <p>
            Another disruption in the area of clinical trials is simulation.
            With personalized medicine, patients with life altering disease have
            a better chance at survival but, the room for error is very slim, a
            good way to mitigate the risks is clinical trial simulations as this
            affords the pharma the opportunity to predict with a high level of
            precision, possible reactions and adjust for it. One big Pharma
            company already utilizing the potential of this process is Moderna
            at it's Norwood facility.
          </p>
          <h3 class="font-weight-bold">
            Distribution and Supply chain
          </h3>
          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636108566/images_-_2021-10-21T010935.800_hn55ev.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>

          <p>
            Getting critical pharmaceutical products to the last mile has been a
            nagging concern for more than a while, millions of these products
            including vaccines get either wasted or lose their efficacy yearly
            due to inefficiencies in the supply chain. Administering impotent
            drugs or vaccines to patients is a critical issue that reflects
            badly on the pharmaceutical industry and the healthcare industry in
            general, to avoid any backlash from these sort of inefficiencies,
            pharma have to recall these products once identified or even pay
            fines and claims, this leads to billions in revenue loss and
            lawsuits claims yearly.
          </p>

          <p>
            Visibility in the form of Real-Time monitoring has really been
            helpful in this regard, IoT enabled Data Loggers are engaged by
            pharmaceutical companies to monitor their products in real time and
            ensure all their products get to the last mile in the best
            condition. Other Devices with the capabilities of Dataloggers are
            Rechargeable Smart Cold Boxes used in getting temperature sensitive
            pharma products like vaccines, insulin shots and Glaucoma eye drops
            to the last mile.
          </p>
          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1636109015/images_-_2021-10-21T010159.974_etrzyw.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>
          <p>
            Generally, IoT and AI/ML has really afforded the pharmaceutical
            industry a lot of notable advancements but, there's still a lot of
            room for more applications. Grand view research via Bloomberg news
            revealed that the AI health market will hit a market size of $390.9
            billions dollars in size by 2027, this is reflective of the numerous
            possible applications within the sector still in the pipeline.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
import image7 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
// import image5 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
import image8 from "../../assets/blog new/pharmaceuticall.png";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image7,
        content:
          "Digitalization in the African retail pharmaceutical sector (With Shelflife by Field intelligence and mPharma)",
        date: "Sept 19, 2021",
        link:
          "/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          "Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "Sept 12, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image8,
        content:
          "Gricd works with ISN to assure product integrity and avert waste risk.",
        date: "Sept 18, 2021",
        link:
          "/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Medical Consultant Speaks On The Role of Storage In Managing Diabetes.",
                item:
                  "https://gricd.com/blog/medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes",
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
.pic-size {
  width: 19rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
